/* Nav */
#main-nav {
  background-color: rgb(86 86 86);
}

#main-nav #brand-container {
  justify-content: center;
}

#main-nav .nav {
  justify-content: end;
}

#main-nav #brand-container p {
  font-size: 1rem;
}

nav a, nav a.nav-link, nav p {
  color: #00c9ff;
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
}

nav a:hover, nav a.nav-link:hover {
  color: #00ffb6;
  font-family: 'Quicksand', sans-serif;
}

nav p {
  margin: 0;
}
/* End Nav */

/* Intro Section */
#intro-section {
  margin: 10em 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#intro-section #intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 5em;
}

#intro-section #memoji-pic {
  height: 150px;
  width: auto;
  border-radius: 25%;
  box-shadow: 0px 5px 7px grey;
  transition: all .2s ease-in-out;
}

#intro-section #memoji-pic:hover {
  transform: scale(1.05);
}

#intro-section #subtitle {
  margin-bottom: 0;
}
/* End Intro Section */

/* About Section */
#career-section {
  margin: 10em 0;
  padding: 5em 0;
  background-color: #d7d7d7;
  position: relative;
}

#career-section .career-position {
  font-family: 'Quicksand', sans-serif;
}

#career-section #roadload {
  transition: 1.5s ease-in;
  transform: translate(-100%);
}

#career-section #rjm {
  text-align: end;
  transition: 1.5s ease-in;
  transform: translate(100%);
}
/* End About Section */

/* Portfolio Section */
#portfolio-section {
  margin: 10em 0;
}

#portfolio-section a {
  position: relative;
  margin: .75rem 0;
}

#portfolio-section a .portfolio-img {
  width:  100%;
  height: 300px;
  object-fit: cover;
  border: 1px solid black;
  border-radius: 2rem;
  box-shadow: 0px 5px 7px gray;
  transition: all .75s ease-in-out;
}

#portfolio-section a:hover .portfolio-img, #portfolio-section a:hover .portfolio-img-overlay { 
  transform: scale(1.05); 
}

#portfolio-section a .portfolio-img-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .75);
  opacity: 0;
  border-radius: 2rem;
  font-size: 20px;
  padding: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .75s ease-in-out;
}

#portfolio-section a:hover .portfolio-img-overlay {
  opacity: 1;
}

#portfolio-section a .overlay-text {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  font-weight: 900;
  font-size: 1em;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
}

#portfolio-section a:hover .overlay-text {
  display: flex;
}
/* End Portfolio Section */

/* Footer Section */
footer {
  background-color: #00c9ff;
  color: #ffffff;
}

footer nav {
  display: flex;
  justify-content: flex-start;
}

footer nav a, footer nav a.nav-link {
  color: #ffffff;
  font-weight: bold;
}

footer nav a:hover, footer nav a.nav-link:hover {
  color: #00ffb6;
}

footer #copyright-container {
  display: flex;
  justify-content: flex-end;
}
/* End Footer Section */

/* General */
.blue-text {
  color: #00c9ff;
  font-family: 'Quicksand', sans-serif;
}

.year-range {
  opacity: 0.5;
}

hr {
  width: 90%;
  border-width: 2px;
  border-color: #00c9ff;
}

body {
  background-color: rgb(242, 242, 242);
}
/* End General */

@media (max-width: 767px) {
  #main-nav .nav, #main-nav #brand-container {
      justify-content: center;
      text-align: center;
  }

  footer nav, footer #copyright-container {
      justify-content: center;
  }
}
